.loader {
  width: 14px;
  --b: 4px; 
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 2px;
  background: conic-gradient(#0000 10%, #0C53B7) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
    radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation:l4 2s infinite steps(10);
  margin-left: 3px;
}
@keyframes l4 {to{transform: rotate(1turn)}}

.rpv-default-layout__body {
  padding-top: 4rem !important;
}

/* Gleap feedback icon button overide default styling */
.bb-feedback-button-icon {
  box-shadow: 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 0px 20px rgba(0, 0, 0, 0.1) !important;
  .bb-feedback-button .bb-logo-logo {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0px !important;
    left: 0px !important;
    object-fit: cover !important;
    border-radius: 50% !important;
    animation-duration: 0.3s !important;
    animation-fill-mode: both !important;
    animation-name: bbZoomIn !important;
  }
}